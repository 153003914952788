import type { DecodedIdToken } from 'firebase-admin/lib/auth/token-verifier';

export const isInternalSpeechifyUser = (authProps: DecodedIdToken | null) => {
  if (!authProps) {
    return false;
  }
  const { email_verified: isVerified } = authProps;
  const email = authProps.email || '';
  return (email.endsWith('@speechify.com') || email.endsWith('@getspeechify.com')) && isVerified;
};
