// Configuration file for feature flags
import type { LibraryVersionConfig } from './utils/libraryVersion';

/**
 * Configuration for the Library Version feature flag
 */
export const libraryVersionConfig: LibraryVersionConfig = {
  // Percentage of users to assign to V2 (0-100)
  percentageRollout: 25,
  // List of specific user emails to force-assign to V2
  forcedV2Emails: [
    'albertusangga.98@gmail.com',
    'vamseems@gmail.com',
    // User community the the UX Research team has recruited
    'matte.carpi@gmail.com',
    'josiejarvis@gmail.com',
    'theogangi@gmail.com',
    'zanboor13@gmail.com',
    'g7rpspbhsk@privaterelay.appleid.com', // Reza
    'saberrg@gmail.com',
    'lauriestark@gmail.com',
    'shawn@sharetraining.org',
    'rodrickgomez@gmail.com',
    'rodrickgomez+speechify@gmail.com', // Rodrick Gomez
    'karli.flake@gmail.com',
    'christianjosiahclampitt@gmail.com',
    'mpnb824ymr@privaterelay.appleid.com', // Christian Clampitt
    'mnkern16@gmail.com'
  ]
};
