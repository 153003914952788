import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import { PageLoader } from 'components/common/loaders/PageLoader';
import { FeatureNameEnum, WebAppLibraryOverhaulVariant } from 'config/constants/featureDefinitions';
import { logVariant } from 'hooks/useFeatureFlags';
import { updateSessionAttributes } from 'lib/observability';
import { withServerAuth } from 'modules/auth/hocs/pages/withServerAuth';
import { libraryVersionConfig } from 'modules/featureFlags/config';
import { LibraryVersion, determineLibraryVersion } from 'modules/featureFlags/utils/libraryVersion';

const LibraryV1 = dynamic(() => import('modules/library/containers/LibraryV1'), {
  ssr: false,
  loading: () => <PageLoader isLoading className="bg-bg-prim-w-b" />
});
const LibraryV2 = dynamic(() => import('modules/library/containers/LibraryV2'), {
  ssr: false,
  loading: () => <PageLoader isLoading className="bg-bg-prim-w-b" />
});

// Using LibraryVersion enum imported from feature flag utils

const Home = (props: { libraryVersion: LibraryVersion }) => {
  useEffect(() => {
    // Log the variant when the component mounts
    const variant = props.libraryVersion === LibraryVersion.V2 ? WebAppLibraryOverhaulVariant.ENABLED : WebAppLibraryOverhaulVariant.CONTROL;

    updateSessionAttributes({
      [FeatureNameEnum.WEB_APP_LIBRARY_OVERHAUL]: variant
    });

    logVariant(FeatureNameEnum.WEB_APP_LIBRARY_OVERHAUL, variant);
  }, [props.libraryVersion]);

  if (props.libraryVersion === LibraryVersion.V2) {
    return <LibraryV2 />;
  }
  return <LibraryV1 />;
};

const { page: AuthenticatedHome, getServerSideProps } = withServerAuth(Home, {
  getServerSideProps: async (_, authProps) => {
    // Determine the library version for the user based on the feature flag configuration
    const libraryVersion = determineLibraryVersion(authProps.auth, libraryVersionConfig);

    return {
      props: {
        libraryVersion
      }
    };
  }
});

export default AuthenticatedHome;

export { getServerSideProps };
