import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { PageLoader } from 'components/common/loaders/PageLoader';
import { logError } from 'lib/observability';
import { useAuthStore } from 'modules/auth/store/authStore';
import { isInternalSpeechifyUser } from 'modules/auth/utils/internalUser';
import { handleCustomTokenAuth, handleSessionAuth } from 'utils/withAuth';

import { getNextJsRedirect as getRedirectIfUnauthenticated } from '../../utils/authUtils';
import { AuthenticatedPage, BaseProps, ServerSideAuthParameters, WithAuthHigherOrderComponent, WithServerAuthProps } from '../../utils/types';

const DEFAULT_UNAUTHENTICATED_REDIRECT_TO = '/login';

export const withServerAuth = <Props extends BaseProps = BaseProps>(
  PageComponent: AuthenticatedPage<Props>,
  {
    ifUnauthenticatedRedirectTo = DEFAULT_UNAUTHENTICATED_REDIRECT_TO,
    withClientSideChromeExtensionAuthFallback = true,
    getServerSideProps
  }: ServerSideAuthParameters<Props> = {}
): WithAuthHigherOrderComponent<Props> => {
  const WithAuthPageComponent = (props: WithServerAuthProps<Props>) => {
    const router = useRouter();
    const user = useAuthStore(state => state.user);

    useEffect(() => {
      if (!withClientSideChromeExtensionAuthFallback) return;

      const onFailure = (error: unknown) => {
        logError(new Error(`withServerAuth, useEffect, onFailure: ${error}`));
        window.location.href = `/login?returnTo=${encodeURIComponent(window.location.href)}`;
      };

      const onSuccess = () => {};

      if (router.query.ctna) {
        handleCustomTokenAuth({ customToken: router.query.ctna as string, onSuccess, onFailure });
        return;
      }

      handleSessionAuth({ onSuccess, onFailure, verifiedCookie: props.auth });
    }, [router, props.auth]);

    return user ? <PageComponent {...props} /> : <PageLoader isLoading className="bg-bg-prim-w-b" />;
  };
  WithAuthPageComponent.displayName = `withAuthPageComponent(${PageComponent.displayName || PageComponent.name})`;

  return {
    page: WithAuthPageComponent,
    getServerSideProps: async (context: GetServerSidePropsContext) => {
      const noSessionReturn = getRedirectIfUnauthenticated(context, ifUnauthenticatedRedirectTo);
      if (typeof window === 'undefined') {
        try {
          // Note: we need to do dynamic import and only runs it on the server side, because somehow even though technically `getServerSideProps` function
          //  should only be run on server side, due to the single usage of `withAuthServerSide` from the pages component
          //  it makes this function also run on the client side during compilation causing webpack error.
          const { auth: firebaseAdminAuth } = await import('../../../../config/api/firebase-admin');

          const decodedIdToken = await firebaseAdminAuth.verifySessionCookie(context.req.cookies.session || '', true);

          const authenticatedProps = {
            auth: decodedIdToken,
            isInternalSpeechifyUser: isInternalSpeechifyUser(decodedIdToken)
          } as WithServerAuthProps<Props>;

          const result = await (getServerSideProps ?? (() => ({ props: {} })))(context, authenticatedProps);
          if ('props' in result) {
            return {
              ...result,
              props: {
                ...authenticatedProps,
                ...result.props
              }
            } as GetServerSidePropsResult<WithServerAuthProps<Props>>;
          }
          return result;
        } catch (e) {
          // TODO(albertusdev): Integrate with lib/observability and emit server-side error
          console.error(`withAuthServerSideProps error: ${e}`);
          if (withClientSideChromeExtensionAuthFallback) {
            console.log('Client-side auth fallback with Chrome extension is enabled');
            return { props: {} } as GetServerSidePropsResult<WithServerAuthProps<Props>>;
          }
          return noSessionReturn;
        }
      }
      // technically this should never execute since this function is only run on server side.
      // TODO(albertusdev): Integrate Observability library and emit server-side warning if the code runs below this block.
      return { props: {} } as GetServerSidePropsResult<WithServerAuthProps<Props>>;
    }
  };
};
