import type { DecodedIdToken } from 'firebase-admin/lib/auth/token-verifier';
import md5 from 'md5'; // Use the dedicated md5 package

import { isInternalSpeechifyUser } from 'modules/auth/utils/internalUser';

export enum LibraryVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export interface LibraryVersionConfig {
  /**
   * Percentage of users to assign to V2, between 0 and 100
   * 0 means no users are assigned to V2, 100 means all users are assigned to V2
   */
  percentageRollout: number;
  /**
   * List of user emails that should be forced to V2 regardless of percentage rollout
   */
  forcedV2Emails: string[];
}

/**
 * Generates a deterministic hash value between 0-100 from a string
 * @param input String to hash
 * @returns A deterministic hash value between 0-100
 */
export const generateDeterministicHash = (input: string): number => {
  // Use md5 which has better distribution properties than djb2
  const md5Hash = md5(input);

  // Take first 8 chars of hash (32 bits) and convert to integer
  const hashInt = parseInt(md5Hash.substring(0, 8), 16);

  // Use modulo with prime number 101 for better distribution
  return hashInt % 101;
};

/**
 * Deterministically assigns a user to a library version based on user ID
 * @param userId User ID to use for assignment
 * @param percentage Percentage of users to assign to V2 (0-100)
 * @returns True if the user should be assigned to V2, false otherwise
 */
export const isUserInPercentageRollout = (userId: string, percentage: number): boolean => {
  if (percentage <= 0) return false;
  if (percentage >= 100) return true;

  // Get normalized hash value for the user ID
  const normalizedHash = generateDeterministicHash(userId);

  // If normalizedHash is less than percentage, assign to V2
  return normalizedHash < Math.ceil((percentage * 101) / 100);
};

/**
 * Determines the library version for a user based on configuration
 */
export const determineLibraryVersion = (authProps: DecodedIdToken | null, config: LibraryVersionConfig): LibraryVersion => {
  if (!authProps) {
    return LibraryVersion.V1;
  }

  // Internal Speechify users always get V2
  if (isInternalSpeechifyUser(authProps)) {
    return LibraryVersion.V2;
  }

  const { percentageRollout, forcedV2Emails } = config;
  const userEmail = authProps.email || '';

  // Check if user's email is in the forced V2 list
  if (forcedV2Emails.includes(userEmail.toLowerCase())) {
    return LibraryVersion.V2;
  }

  // Check if user is in the percentage rollout for V2
  if (isUserInPercentageRollout(authProps.uid, percentageRollout)) {
    return LibraryVersion.V2;
  }

  // Default to V1
  return LibraryVersion.V1;
};
