import { ParsedUrlQuery } from 'querystring';

import type { GetServerSidePropsContext, Redirect } from 'next/types';

import type { ServerSideAuthParameters } from './types';

const DEFAULT_REDIRECT_TO = '/login';

export function getNextJsRedirect<Params extends ParsedUrlQuery = ParsedUrlQuery>(
  context: GetServerSidePropsContext<Params>,
  redirectTo: ServerSideAuthParameters['ifUnauthenticatedRedirectTo'] = DEFAULT_REDIRECT_TO
): { redirect: Redirect } {
  const destination = typeof redirectTo === 'function' ? redirectTo(context) : redirectTo;
  return {
    redirect: {
      destination,
      permanent: false
    }
  };
}
